import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import striptags from 'striptags';

import Helmet from '../components/Helmet';
import Layout from '../components/Layout';
import WordPressPostContent from '../components/WordPressPostContent';
import DebugGrid from '../components/DebugGrid';
import PrismLoader from '../components/PrismLoader';
import { addTrailingSlash } from '../utils/helper';

import css from './Page.module.css';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        siteUrl: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    wordpressPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      modified: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const defaultProps = {

};

function Page({
  location: {
    pathname,
  },
  data: {
    site: {
      siteMetadata: {
        title: siteTitle,
        siteUrl,
      },
    },
    wordpressPage: {
      title,
      date,
      modified,
      excerpt,
      content,
    },
  },
}) {
  const schemata = [
    {
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': addTrailingSlash(`${siteUrl}${pathname}`),
      },
      headline: title,
      description: striptags(excerpt),
      author: {
        '@id': '#author',
      },
      datePublished: date,
      dateModified: modified,
      publisher: {
        '@id': '#publisher',
      },
    },
    {
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
      ],
    },
  ];

  return (
    <>
      <Helmet
        path={pathname}
        title={title}
        description={striptags(excerpt)}
        type="article"
        created={date}
        updated={modified}
        topLevelSchemata={schemata}
      />
      <PrismLoader />
      <Layout>
        <article className="ft-gap-margin ft-gap-margin--col ft-gap-margin--lg">
          <header className="ft-grid">
            <h1 className={`${css.page__title} ft-margin-reset--y`}>{title}</h1>
          </header>
          {content && (
            <div className={`${css.page__content} ft-grid`}>
              <div>
                <WordPressPostContent content={content} />
              </div>
            </div>
          )}
        </article>
      </Layout>
      <DebugGrid />
    </>
  );
}

export const query = graphql`
  query PageQuery($pageId: Int!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wordpressPage(
      wordpress_id: { eq: $pageId }
      status: { eq: "publish" }
    ) {
      title
      date
      modified
      excerpt
      content
    }
  }
`;

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
